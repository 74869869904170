/**
 * Generate from url: https://localhost:44305/swagger/v1/swagger.json
 * It is recommended not to modify the document
 * Total count: 7
 **/
import type {
  ArticleCreateOrUpdateDto,
  ArticleDto,
  ArticlePrevAndNextDto,
  PagedResultDto,
} from './types';
import { api } from 'boot/axios';

/**
 * *TODO* PATCH /api/app/article/{id}/active
 **/
export function active(id: string) {
  return api.patch<void>(`/api/app/article/${id}/active`, {});
}

/**
 * *TODO* POST /api/app/article
 **/
export function create(payload: ArticleCreateOrUpdateDto) {
  return api.post<ArticleDto>('/api/app/article', {
    data: payload,
  });
}

/**
 * *TODO* PATCH /api/app/article/{id}/disactive
 **/
export function disactive(id: string) {
  return api.patch<void>(`/api/app/article/${id}/disactive`, {});
}

/**
 * *TODO* GET /api/app/article/{id}
 **/
export function get(id: string) {
  return api.get<ArticlePrevAndNextDto>(`/api/app/article/${id}`, {});
}

/**
 * *TODO* GET /api/app/article
 **/
export function getList(params: {
  filter?: string | undefined;
  category?: string | undefined;
  isActive?: boolean | undefined;
  beginTime?: string | undefined;
  endTime?: string | undefined;
  sorting?: string | undefined;
  skipCount?: number | undefined;
  maxResultCount?: number | undefined;
}) {
  return api.get<PagedResultDto<ArticleDto>>('/api/app/article', {
    params: params,
  });
}

/**
 * *TODO* DELETE /api/app/article/{id}
 **/
export function remove(id: string) {
  return api.delete<void>(`/api/app/article/${id}`, {});
}

/**
 * *TODO* PUT /api/app/article/{id}
 **/
export function update(id: string, payload: ArticleCreateOrUpdateDto) {
  return api.put<ArticleDto>(`/api/app/article/${id}`, {
    data: payload,
  });
}

export default {
  active,
  create,
  disactive,
  get,
  getList,
  remove,
  update,
};
