import { defineStore } from 'pinia';
import { getList, get } from 'src/api/Article';
import { ArticleDto, ArticlePrevAndNextDto } from 'src/api/types';

interface ArticleStoreState {
  article?: ArticlePrevAndNextDto;
  articles: ArticleDto[];
  total: number;
}

export const useArticleStore = defineStore('article', {
  state: (): ArticleStoreState => ({
    article: undefined,
    articles: [],
    total: 0,
  }),
  actions: {
    async fetchList(params: {
      isActive?: boolean | undefined;
      sorting?: string | undefined;
      skipCount?: number | undefined;
      maxResultCount?: number | undefined;
    }) {
      const { data } = await getList(
        Object.assign({ sorting: 'activeDate descending' }, params)
      );
      this.articles = data.items ?? [];
      this.total = data.totalCount ?? 0;
    },
    async fetch(id: string) {
      this.article = undefined;
      const { data } = await get(id);
      this.article = data;
    },
  },
});
