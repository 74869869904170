import { PageWithRegionsDto, RegionDto } from 'src/api/types';

export function filterIsActive(data: PageWithRegionsDto) {
  if (data.regions) {
    const filterChildren = (regions: RegionDto[]) => {
      regions.forEach((region) => {
        if (region.children) {
          region.children = region.children.filter((t) => t.isActive);
          filterChildren(region.children);
        }
      });
    };

    data.regions = data.regions.filter((t) => t.isActive);
    filterChildren(data.regions);
  }
}
