/**
 * Generate from url: https://localhost:44305/swagger/v1/swagger.json
 * It is recommended not to modify the document
 * Total count: 5
 **/
import type {
  MetadataCreateOrUpdateDto,
  MetadataDto,
  PagedResultDto,
} from './types';
import { api } from 'boot/axios';

// const api = getApi()

/**
 * *TODO* POST /api/app/metadata
 **/
export function create(payload: MetadataCreateOrUpdateDto) {
  return api.post<MetadataDto>('/api/app/metadata', {
    data: payload,
  });
}

/**
 * *TODO* GET /api/app/metadata/{id}
 **/
export function get(id: string) {
  return api.get<MetadataDto>(`/api/app/metadata/${id}`, {});
}

/**
 * *TODO* GET /api/app/metadata
 **/
export function getList(params: {
  isActive?: boolean | undefined;
  filter?: string | undefined;
  sorting?: string | undefined;
  skipCount?: number | undefined;
  maxResultCount?: number | undefined;
}) {
  return api.get<PagedResultDto<MetadataDto>>('/api/app/metadata', {
    params: params,
  });
}

/**
 * *TODO* DELETE /api/app/metadata/{id}
 **/
export function remove(id: string) {
  return api.delete<void>(`/api/app/metadata/${id}`, {});
}

/**
 * *TODO* PUT /api/app/metadata/{id}
 **/
export function update(id: string, payload: MetadataCreateOrUpdateDto) {
  return api.put<MetadataDto>(`/api/app/metadata/${id}`, {
    data: payload,
  });
}

export default {
  create,
  get,
  getList,
  remove,
  update,
};
